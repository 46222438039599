import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { reducer as formReducer } from "redux-form";
import employeeBasic from "./employeeBasics/reducer";
import employeeDDL from "./employeeBasics/employeeDDLReducer";
import employeePersonalDetails from "./personalDetails/reducer";
import employeeContactDetails from "./contactDetails/reducer";
import employeeSalaryDetails from "./salaryDetails/reducer";
import masterParameters, {
  LateMinutesDeductionFormulas,
} from "./masterParameters/reducer";
import masterDDL, {
  EmployeesDDL,
  RequestStatus,
  DepartmentDDL,
  MasterAssetDDL,
} from "./masterDDL/reducer";
import allowancesDetails from "./allowancesDetails/reducer";
import deductionsDetails from "./deductionsDetails/reducer";
import passportVisaDetails from "./passportVisaLabourCardDetails/reducer";
import experiencesDetails from "./experiencesDetails/reducer";
import qualificationsDetails from "./qualificationsDetails/reducer";
import incrementRecordsHistory from "./incrementRecordsHistory/reducer";
import annualLeaves from "./leaveManagement/leaveAnnual/reducer";
import leaveOthers from "./leaveManagement/leaveOthers/reducer";
import leaveCompensatory from "./leaveManagement/leaveCompensatory/reducer";
import leaveRequest from "./leaveManagement/leaveRequest/reducer";
import miscellaneousRecords from "./miscellaneousRecords//reducer";
import expenses from "./expenses/reducer";
import expenseRequests from "./expenses/expenseRequests/reducer";
import employeeDocuments from "./documents/reducer";
import notes from "./notes/reducer";
import complaints from "./complaints/reducer";
import companies from "./Companies/CompanyBasics/reducer";
import { companiesDDL } from "./Companies/CompanyBasics/reducer";
import absentDaysDetails from "./absentDaysDetails/reducer";
import companyTenancyContracts from "./Companies/tenancyContracts/reducer";
import insurances from "./Companies/insurance/reducer";
import companyDocuments from "./Companies/documents/companyDocuments/reducer";
import publicDocuments from "./Companies/documents/publicDocuments/reducer";
import miscContracts from "./Companies/miscContracts/reducer";
import companyMasterDDL from "./Companies/CompanyMasterDDL/reducer";
import companyWeekDays from "./Companies/companyWeekDays/reducer";
import companyPublicHolidays from "./Companies/companyPublicHolidays/reducer";
import loanEmployeeDDL from "./loans/loansDDL/reducer";
import Loans from "./loans/reducer";
import LoanRequests from "./loans/LoanRequests/reducer";
import payrollMasterDDL from "./payroll/payrollMasterDDL/reducer";
import PayrollAllowances from "./payroll/payrollAllowance/reducer";
import PayrollDeductions from "./payroll/payrollDeduction/reducer";
import PayrollLoans from "./payroll/payrollLoan/reducer";
import PayrollWorkOnProjects from "./payroll/payrollWorkOnProject/reducer";
import AttendancesPeriod, {
  AttendanceDDL,
} from "./attendance/attendanceDDL/reducer";
import Attendances from "./attendance/reducer";
import AttendanceDetails from "./attendance/attendanceDetails/reducer";
import payrollEmployees, {
  PayrollVoucher,
  PayrollMonthYear,
  PayrollHeaderVoucher,
  PayrollAllowanceDeductions,
} from "./payroll/reducer";
import { companyEmployeeDDL } from "./Companies/CompanyBasics/reducer";
import {
  db_Ids,
  ui_Ids,
  local_payroll_values,
  local_attendance_values,
  EmployeeDDL,
  EmployeesStatuses,
  EmployeeExcelImports,
} from "./global/reducer";
import Reports, {
  ReportGroupsDDL,
  ReportsDDL,
  ReportDetails,
} from "./reports/reducer";
import Masters, { MastersTblDDL } from "./Masters/reducer";
import BankRoutingCodes from "./BankRoutingCodes/reducer";
import GratuityMastersDDL from "./settings/gratuity/gratuityMasterDDL/reducer";
import GratuityRulesAboveNyrLimited from "./settings/gratuity/gratuityRulesAboveNyrLimited/reducer";
import GratuityRulesResignationLimited from "./settings/gratuity/gratuityRulesResignationLimited/reducer";
import GratuityRulesResignationUnLimited from "./settings/gratuity/gratuityRulesResignationUnLimited/reducer";
import GratuityRulesAboveNyrUnLimited from "./settings/gratuity/gratuityRulesAboveNyrUnLimited/reducer";
import GratuityRulesTerminationLimited from "./settings/gratuity/gratuityRulesTerminationLimited/reducer";
import GratuityRulesTerminationUnLimited from "./settings/gratuity/gratuityRulesTerminationUnLimited/reducer";
import MasterOverTimeFormulas from "./settings/overTime/masterOverTimeFormulas/reducer";
import MasterLateMinutesDeductions from "./settings/overTime/masterLateMinutesDeductions/reducer";
import MasterVacationRules from "./settings/misc/vacations/reducer";
import MiscMasterCurrenciesDDL from "./settings/misc/general/miscMasterDDL/reducer";
import WpsConfigSettings from "./settings/wpsConfig/reducer";
import AttendanceSettings from "./settings/Attendance/reducer";
import Users from "./users/reducer";
import Managers from "./users/managers/reducer";
import MasterTasks from "./users/masterTasks/reducer";
import UserPermissionsTask from "./users/userPermissionsTask/reducer";
import Vehicles from "./vehicles/reducer";
import VehicleUsageHistory from "./vehicles/usageHistory/reducer";
import VehiclePetrolExpense from "./vehicles/petrolExpense/reducer";
import VehicleRepairHistory from "./vehicles/repairHistory/reducer";
import VehicleDocuments, {
  VehicleDocumentTypeDDL,
} from "./vehicles/documents/reducer";
import { VehiclesDDL } from "./vehicles/reducer";
import Settlements from "./settlements/reducer";
import OtherAllowances from "./settlements/otherAllowances/reducer";
import OtherDeductions from "./settlements/otherDeductions/reducer";
import LoanSettlements from "./settlements/loanSettlements/reducer";
import {
  PayrollListForWps,
  SettingWpsConfig,
  PayInGroupsMaster,
} from "./payroll/sifFile/reducer";
import SalaryBreakdowns from "./payroll/salaryBreakdown/reducer";
import RequestLeaves from "./manageRequests/requestLeaves/reducer";
import RequestLoans from "./manageRequests/requestLoans/reducer";
import RequestExpenses from "./manageRequests/requestExpenses/reducer";
import AuthReducer from "./authentication/reducer";
import {
  EmployeeBasicDetails,
  EmployeeSalaryDetails,
  EntitledLeaveDaysDetails,
} from "./employeeProfile/reducer";
import Dashboard from "./users/dashboard/reducer";
import OrgChart from "./orgChart/reducer";
import configReducer from "../store/reducer";
import Assets from "./assets/reducer";
import EmployeeAssets from "./employeeAssets/reducer";
import GeneralRequests from "./generalRequests/reducer";
const persistConfig = {
  key: "root",
  storage,
  // stateReconciler: hardSet
  // employees: ["employees"],
};
const rootReducer = (state, action) =>
  combineReducers({
    configReducer,
    form: formReducer,
    employees: employeeBasic,
    personalDetails: employeePersonalDetails,
    contactDetails: employeeContactDetails,
    salaryDetails: employeeSalaryDetails,
    masterParameters,
    allowancesDetails,
    deductionsDetails,
    passportVisaDetails,
    experiencesDetails,
    qualificationsDetails,
    incrementRecordsHistory,
    annualLeaves: annualLeaves,
    leaveOthers,
    leaveCompensatory,
    leaveRequest,
    miscellaneousRecords,
    expenses,
    expenseRequests,
    employeeDocuments,
    notes,
    absentDays: absentDaysDetails,
    complaints,
    companyTenancyContracts,
    employeeBasicDDL: employeeDDL,
    masterDDL,
    companyMasterDDL,
    companyEmployeeDDL,
    companies,
    insurances,
    publicDocuments,
    companyDocuments,
    miscContracts,
    companiesDDL,
    companyWeekDays,
    db_Ids,
    ui_Ids,
    companyPublicHolidays,
    loanEmployeeDDL,
    Loans,
    LoanRequests,
    payrollMasterDDL,
    payrollEmployees,
    PayrollVoucher,
    PayrollMonthYear,
    local_payroll_values,
    PayrollHeaderVoucher,
    PayrollAllowanceDeductions,
    PayrollAllowances,
    PayrollDeductions,
    PayrollLoans,
    PayrollWorkOnProjects,
    LateMinutesDeductionFormulas,
    AttendancesPeriod,
    local_attendance_values,
    Attendances,
    AttendanceDetails,
    AttendanceDDL,
    ReportGroupsDDL,
    ReportsDDL,
    ReportDetails,
    EmployeesDDL,
    DepartmentDDL,
    Masters,
    MastersTblDDL,
    BankRoutingCodes,
    GratuityMastersDDL,
    GratuityRulesAboveNyrLimited,
    GratuityRulesTerminationLimited,
    GratuityRulesResignationLimited,
    GratuityRulesAboveNyrUnLimited,
    GratuityRulesResignationUnLimited,
    GratuityRulesTerminationUnLimited,
    MasterOverTimeFormulas,
    MasterLateMinutesDeductions,
    MasterVacationRules,
    MiscMasterCurrenciesDDL,
    WpsConfigSettings,
    AttendanceSettings,
    Users,
    Managers,
    MasterTasks,
    Reports,
    UserPermissionsTask,
    Vehicles,
    VehicleUsageHistory,
    VehiclePetrolExpense,
    VehicleRepairHistory,
    VehicleDocuments,
    VehiclesDDL,
    VehicleDocumentTypeDDL,
    EmployeeDDL,
    EmployeesStatuses,
    EmployeeExcelImports,
    Settlements,
    OtherAllowances,
    OtherDeductions,
    LoanSettlements,
    PayrollListForWps,
    SettingWpsConfig,
    PayInGroupsMaster,
    SalaryBreakdowns,
    RequestStatus,
    RequestLeaves,
    RequestLoans,
    RequestExpenses,
    EmployeeBasicDetails,
    EmployeeSalaryDetails,
    EntitledLeaveDaysDetails,
    Dashboard,
    OrgChart,
    Assets,
    EmployeeAssets,
    MasterAssetDDL,
    auth: AuthReducer,
    GeneralRequests,
  })(
    action.type === "LOGIN" || action.type === "LOGOUT" ? undefined : state,
    action
  );
export default persistReducer(persistConfig, rootReducer);
// export default rootReducer;
